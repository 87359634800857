html,
body,
#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F6FAFF;
}
html {
  min-height: 100%;
}
body {
  flex-grow: 1;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
#root {
  flex-grow: 1;
}
[flex] {
  flex: initial !important;
  flex-wrap: initial !important;
}
[flex~='row'] {
  display: flex !important;
  flex-direction: row !important;
}

[flex~='column'][flex~='halign-stretch'] {
  align-items: stretch !important;
}

[flex~='row'][flex~='valign-start'] {
  align-items: flex-start !important;
}
