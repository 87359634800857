/* Background color, display, etc set in the index.css */

.App{
  text-align: center;
  padding: 10px 0;
}

.Main{
  max-width: 1000px;
  margin: auto; /* center */
  padding: 20px;
}

.order-form{
  text-align: left;
  padding: 16px;
}

.two-columns{
  column-count: 2;
  column-gap: 20px;
}

.two-columns > div {
  margin-bottom: 20px;
  break-inside: avoid;
}

.map{
  width: 100%;
  height: 75vh;
}

.review-map{
  width: 100%;
  height: 65vh;
}

.tracks-map{
  width: 100%;
  height: 75vh;
  max-height: 1000px;
}

.order-preview{
  max-width: 600px;
  margin: auto;
}
